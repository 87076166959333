import axios from '../../node_modules/axios';
//import { useToast } from "vue-toastification";
import _default, { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
// import { useNotification } from "@kyvg/vue3-notification";
import Toastify from 'toastify-js'
import { bottom } from '@popperjs/core';



export default {

	data: () => {
		return {
			api: null,
			toast: null,

			admin_users: [],

			enable_cart_reports: true,
			
		}
	},

    mounted: function(){

		var self = this;

		let axios_config = {
			baseURL: this.api_url,
			timeout: 30000,
		};

		moment.updateLocale(moment.locale(), { invalidDate: "-" })

		if( this.token && this.token ){
			axios_config.headers = { 
				'Authorization' : 'Bearer ' + this.token,  
				'CM-Request-Context' : this.request_context ,  
			};
		}

		
        this.api = axios.create(axios_config); 

		
		

		// this.notification = useToast();
		this.notification = {
			success: function( message ){
				
				if( !message )
					message = 'Operazione eseguita correttamente';

				Toastify({
					text: message,
					gravity: bottom,
					className: "toast-success",
				}).showToast();
			},
			error: function( message ){

				if( !message )
					message = 'Errore nella richiesta';

				Toastify({
					text: message,
					gravity: bottom,
					className: "toast-danger",
				}).showToast();
			},
		};
		
    },

	methods: {

        userHasRole: function( role ){

			if( !this.user)
				return false;

			return this.user.role == role;

		},

		logout: function(){
			this.$store.commit('resetAll');
			this.$router.push('/login');
		},


		adminGetUsers: function( callback ){
			var self = this;
	
		   this.api.post( 'user/adminGetUsers')
			.then(function (response) {
			  
				if(response.data.users){
				  self.admin_users = response.data.users;
				}

				if( typeof callback === 'function')
              		callback();
			})
	
		},

		
		setCurrentProfile: function( profile ){

			if(this.current_profile && (profile.id == this.current_profile.id))
				return;

			if( this.cart.items.length > 0 && !this.confirm('Modificando il profilo corrente, il carrello verrà svuotato. Per non perderlo procedere col salvataggio.') )
				return;

			this.$store.commit('setCurrentProfile', profile);
			this.$store.commit('resetCart');
		},

		setCurrentCustomer: function( customer ){

			if(this.current_customer && (customer.id == this.current_customer.id))
				return;


			if( this.cart.items.length > 0 && !this.confirm('Modificando il cliente corrente, il carrello verrà svuotato. Per non perderlo procedere col salvataggio.') )
				return;

			this.$store.commit('setCurrentCustomer', customer);
			this.$store.commit('resetCart');
		},


		setCurrentProduct: function( product ){
			
			this.$store.commit('setCurrentProduct', product);
			
		},

		addItemToCart: function( item ){
			this.$store.commit('addItemToCart', item);
		},

		setCartShippingItems: function( items){
			this.$store.commit('setCartShippingItems', items);
		},

		setCartPromotionItem: function( item ){
			this.$store.commit('setCartPromotionItem', item);
		},

		setCartShippingAddress: function( shipping_address_is_new, shipping_address, shipping_address_new ){
			this.$store.commit('setCartShippingAddress', {
									shipping_address_is_new: shipping_address_is_new, 
									shipping_address: shipping_address, 
									shipping_address_new: shipping_address_new
								});
		},

		removeCartItem: function( item_index ){
			if( this.confirm( 'Rimuovere il prodotto dal carrello?' ) )
				this.$store.commit('removeCartItem', item_index);
		},

		updateCart: _.debounce( function(){
			this.$store.commit('updateCart');
		}, 200),

		calculateCartItemTotal( item ){
			var t = item.price * item.quantity;
			if( item.discount_1 )
				t = t * ((100 - item.discount_1) / 100 );
			if( item.discount_2 )
				t = t * ((100 - item.discount_2) / 100 );
			return this.formatPrice(t);
		},

		formatPrice: function( num ){
			return (Math.round(num * 100) / 100).toFixed(2);
		},

		formatDate: function( dat ){
			return moment( dat ).format('DD/MM/YYYY');
		},

		formatDatetime: function( dat ){
			return moment( dat ).format('DD/MM/YYYY HH:mm');
		},

		confirm: function(message){
			return confirm(message);
		},

		handleApiError( error ){

			console.log( error );

			var message = ' Si è verificato un errore nella richiesta';

			if( error.response && error.response.data && error.response.data.message != undefined)
				message = error.response.data.message;
			else if(error.response && error.response.data && error.response.data.error != undefined)
				message = error.response.data.error;


			this.notification.error( message );
		},

		downloadWarrantyPdf: function(id, code, vat_code){
			
			var self = this;

			var url = 'warranty/downloadPdf/' + id + '/' + code + '/' + vat_code;
      
			this.api.get( url, { responseType: 'blob' } )
			  .then(function (response) {
				
				
				if( response.data.success != undefined && response.data.success == false){
				  self.notification.error( response.data.message );
				}
				else{
				  const url = window.URL.createObjectURL(new Blob([response.data]));
				  const link = document.createElement('a');
				  link.href = url;
				  link.setAttribute('download', 'cm-' + code + '.pdf'); //or any other extension
				  document.body.appendChild(link);
				  link.click();
				}
				  
			  })
			  .catch(function (error) {
				  self.handleApiError( error );
			  });
	  
		},
	},

	computed: {
		...mapState([
			'api_url',
			'request_context',
			'user',
			'token',
			'token_expiration',
			'configuration',
			

			'current_profile',
			'current_customer',
			'current_product',

			'cart',
		  ]),
		userIsLogged: function( ){
			return  (this.user && this.token != null);
		},


	}
}